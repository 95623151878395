import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Helmet from "react-helmet";

import { locale, Ui } from "../..";
import RangeSliderDataProvider from "../RandeSliderDataProvider";
import IdeaProvider from "./IdeaProvider";
import { fetchIdeaLikes, fetchIdeaRange, fetchIdeaShares } from "../Likes/likesReducer";
import { IdeaRangeProvider } from "../Likes";

class IdeaFetcher extends Component {
    static propTypes = {
        idea: PropTypes.shape({
            id: PropTypes.string.isRequired
        }).isRequired,
        fetchIdeaLikes: PropTypes.func.isRequired,
        fetchIdeaRange: PropTypes.func.isRequired,
        fetchIdeaShares: PropTypes.func.isRequired
    };

    componentDidMount() {
        const {
            idea: { id },
            fetchIdeaLikes: dispatchFetchLikes,
            fetchIdeaRange: dispatchFetchRange,
            fetchIdeaShares: dispatchFetchShares
        } = this.props;
        dispatchFetchLikes(id);
        dispatchFetchRange(id);
        dispatchFetchShares(id);
    }

    render() {
        return null;
    }
}

const ConnectedFetcher = connect(
    null,
    {
        fetchIdeaLikes,
        fetchIdeaRange,
        fetchIdeaShares
    }
)(IdeaFetcher);

const Idea = ({
    idea,
    animationStarted,
    isBlackBoxOpened,
    onClickNext,
    isNext,
    isIndexPage,
    isIndexAnimationStarted
}) => (
    <Ui.Layout.CenterCenter zIndex key={idea.id}>
        <ConnectedFetcher idea={idea} />
        <Ui.Idea
            idea={idea}
            onClickNext={onClickNext}
            isNext={isNext}
            animationStarted={animationStarted}
            isBlackBoxOpened={isBlackBoxOpened}
            isIndexPage={isIndexPage}
            isIndexAnimationStarted={isIndexAnimationStarted}
        />
        <Ui.Layout.RangeSliderWrapper
            isNext={isNext}
            animationStarted={animationStarted}
            isBlackBoxOpened={isBlackBoxOpened}
            isIndexPage={isIndexPage}
            isIndexAnimationStarted={isIndexAnimationStarted}
        >
            <Ui.Touchable>
                <IdeaRangeProvider idea={idea}>
                    {({ ideaRange, userSetIdeaRange }) => (
                        <RangeSliderDataProvider
                            ideaRange={ideaRange}
                            isNext={isNext}
                            userSetIdeaRange={userSetIdeaRange}
                        >
                            {({ position, size, onStart, onDrag, onStop, onMouseEnter, storeRef, isDraggingNow }) => (
                                <Ui.RangeSlider
                                    isDraggingNow={isDraggingNow}
                                    emoji={idea.emoji}
                                    position={position}
                                    size={size}
                                    onStart={onStart}
                                    onDrag={onDrag}
                                    onStop={onStop}
                                    onMouseEnter={onMouseEnter}
                                    storeRef={storeRef}
                                />
                            )}
                        </RangeSliderDataProvider>
                    )}
                </IdeaRangeProvider>
            </Ui.Touchable>
        </Ui.Layout.RangeSliderWrapper>
    </Ui.Layout.CenterCenter>
);

Idea.propTypes = {
    idea: PropTypes.shape({}).isRequired,
    animationStarted: PropTypes.bool.isRequired,
    isBlackBoxOpened: PropTypes.bool.isRequired,
    onClickNext: PropTypes.func.isRequired,
    isNext: PropTypes.bool,
    isIndexPage: PropTypes.bool,
    isIndexAnimationStarted: PropTypes.bool
};

Idea.defaultProps = {
    isNext: false,
    isIndexPage: false,
    isIndexAnimationStarted: false
};

const Ideas = ({ isBlackBoxOpened, onClickToggle, isIndexAnimationStarted, isIndexPage }) => (
    <IdeaProvider>
        {({ currentIdea, nextIdea, animationStarted, showNextIdea }) => (
            <>
                {!isIndexPage && (
                    <Helmet>
                        <meta
                            name="viewport"
                            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
                        />

                        <link rel="apple-touch-icon" sizes="180x180" href="/favicon/apple-touch-icon.png" />
                        <link rel="icon" type="image/png" sizes="32x32" href="/favicon/favicon-32x32.png" />
                        <link rel="icon" type="image/png" sizes="16x16" href="/favicon/favicon-16x16.png" />
                        <link rel="mask-icon" href="/favicon/safari-pinned-tab.svg" color="#72be91" />
                        <meta name="apple-mobile-web-app-title" content="Nimax" />
                        <meta name="application-name" content="Nimax" />
                        <meta name="msapplication-TileColor" content="#000000" />
                        <meta name="theme-color" content="#ffffff" />
                        <meta property="og:site_name" content="Агентство Nimax" />
                        <link rel="manifest" href="/favicon/site.webmanifest" />

                        <title>Идея для конкурса в соцсетях — Nimax</title>
                        <meta property="og:title" content="Мне подали классную идею для конкурса в соцсетях" />
                        <meta property="og:description" content={"Советую попробовать генератор конкурсов от Nimax"} />
                        <meta property="og:type" content="article" />
                        <meta property="og:image" content={locale.og.index.image} />
                    </Helmet>
                )}

                <Idea
                    key={currentIdea.id}
                    isIndexPage={isIndexPage}
                    isIndexAnimationStarted={isIndexAnimationStarted}
                    idea={currentIdea}
                    onClickNext={isBlackBoxOpened ? onClickToggle : showNextIdea}
                    animationStarted={animationStarted}
                    isBlackBoxOpened={isBlackBoxOpened}
                />

                {!isIndexPage && (
                    <Idea
                        key={nextIdea.id}
                        idea={nextIdea}
                        onClickNext={showNextIdea}
                        animationStarted={animationStarted}
                        isBlackBoxOpened={isBlackBoxOpened}
                        isNext
                    />
                )}
            </>
        )}
    </IdeaProvider>
);

Ideas.propTypes = {
    isBlackBoxOpened: PropTypes.bool,
    onClickToggle: PropTypes.func,
    isIndexAnimationStarted: PropTypes.bool,
    isIndexPage: PropTypes.bool
};

Ideas.defaultProps = {
    onClickToggle: null,
    isBlackBoxOpened: false,
    isIndexAnimationStarted: false,
    isIndexPage: false
};

export default Ideas;
