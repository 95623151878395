// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-idea-js": () => import("/vercel/workpath0/src/templates/idea.js" /* webpackChunkName: "component---src-templates-idea-js" */),
  "component---src-pages-index-js": () => import("/vercel/workpath0/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

exports.data = () => import("/vercel/workpath0/.cache/data.json")

