import React from 'react';
import PropTypes from 'prop-types';
import locale from '../../locale';
import Text from '../Text/Text';
import Touchable from '../Touchable';
import Button from '../Button/Button';
import Animation from '../Animation/Animation';
import './blackBoxDownload.css';

const onClickDownload = () => {
  window.open(locale.downloadHref, '_blank');
};

const BlackBoxDownload = ({ repostVk, repostFb, reposted }) => (
  <div className="blackBoxDownload-minHeight">
    <div className="blackBoxDownload">
      <div className="blackBoxDownload-wrapper">
        <div className="blackBoxDownload-title">
          <Touchable>
            <Text
              navigo
              white
              heavy
              fontSize={1.8}
              lineHeight={2.1}
              html={locale.blackBox.downloadText}
            />
          </Touchable>
        </div>
        {!reposted && (
          <Animation
            type="17"
            rotate="-155deg"
            hiddenOnPhone
          />
        )}
        <Touchable>
          <div className="blackBoxDownload-buttons">
            {reposted
              ? (
                <Button theme="download" onClick={onClickDownload}>
                  <Text navigo white heavy fontSize={1.7}>
                    {locale.blackBox.download}
                  </Text>
                </Button>
              )
              : (
                <>
                  <Button theme="vk" onClick={repostVk}>
                    <Text navigo white heavy fontSize={1.7}>
                      {locale.vk}
                    </Text>
                    <Text navigo white fontSize={1.6}>
                      16
                    </Text>
                  </Button>
                  <Button theme="fb" onClick={repostFb}>
                    <Text navigo white heavy fontSize={1.7}>
                      {locale.fb}
                    </Text>
                    <Text navigo white fontSize={1.6}>
                      16
                    </Text>
                  </Button>
                </>
              )
            }
          </div>
        </Touchable>
      </div>
    </div>
  </div>
);

BlackBoxDownload.propTypes = {
  reposted: PropTypes.bool.isRequired,
  repostVk: PropTypes.func.isRequired,
  repostFb: PropTypes.func.isRequired,
};

export default BlackBoxDownload;
