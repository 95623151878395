import { Component } from 'react';
import PropTypes from 'prop-types';

const calcTimer = 300;
const scrollBotTimer = 500;

class ToggleBlackBoxOnScroll extends Component {
  static propTypes = {
    isBlackBoxOpened: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    children: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);
    this.alreadyToggled = false;
    this.delta = 0;
    this.lockedByScroll = false;
  }

  componentDidMount() {
    this.delegateEvents();
  }

  componentWillUnmount() {
    this.undelegateEvents();
  }

  toggle = () => {
    const { isBlackBoxOpened, toggle } = this.props;

    if (
      (
        isBlackBoxOpened && this.delta < 0 && !this.lockedByScroll
      ) || (
        !isBlackBoxOpened && this.delta > 0
      )
    ) {
      this.clearDelta();
      toggle();
      this.delta = 0;
      this.alreadyToggled = true;
      setTimeout(this.clearAlreadyToggled, 1000);
    }
  };

  onWheel = (event) => {
    const delta = Math.sign(event.deltaY);
    this.delta += delta;

    this.lockByScrollLogic();

    if (Math.abs(this.delta) > 2 && !this.alreadyToggled) {
      this.toggle();
    }

    this.clearTimeout = setTimeout(this.clearDelta, calcTimer);
  };

  lockByScrollLogic = () => {
    const { scrollTop } = this.bbRef;

    if (scrollTop !== 0) {
      this.lockedByScroll = true;
      clearTimeout(this.lockByScrollTimeout);
    }
    if (scrollTop === 0) {
      this.lockByScrollTimeout = setTimeout(() => {
        this.lockedByScroll = false;
      }, scrollBotTimer);
    }
  }

  clearAlreadyToggled = () => {
    this.alreadyToggled = false;
  }

  clearDelta = () => {
    this.delta = 0;
    clearTimeout(this.clearTimeout);
  };

  storeBBRef = (ref) => {
    this.bbRef = ref;
  }

  delegateEvents() {
    document.addEventListener('wheel', this.onWheel);
  }

  undelegateEvents() {
    document.removeEventListener('wheel', this.onWheel);
  }

  render() {
    const { children } = this.props;
    return children({
      storeBBRef: this.storeBBRef,
    });
  }
}

export default ToggleBlackBoxOnScroll;
