import {
  createStore as reduxCreateStore,
  combineReducers,
  applyMiddleware,
} from 'redux';
import logger from 'redux-logger';
import createSagaMiddleware from 'redux-saga';

import isClient from './isClient';
import rootSaga from './rootSaga';
import {
  loaderReducer,
  ideasReducer,
  repostReducer,
  likesReducer,
} from './components';

const reducer = combineReducers({
  loaderReducer,
  ideasReducer,
  repostReducer,
  likesReducer,
});

const createStore = (initialState = {}) => {
  const sagaMiddleware = createSagaMiddleware();
  const middlewares = [
    sagaMiddleware,
    logger,
  ];
  const store = reduxCreateStore(
    reducer,
    initialState,
    applyMiddleware(...middlewares),
  );
  if (isClient) {
    window.store = store;
    sagaMiddleware.run(rootSaga);
  }
  return store;
};


export default createStore;
