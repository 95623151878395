import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './text.css';

const Text = (props) => {
  const {
    children,
    html,
    bold,
    heavy,
    fontSize,
    italic,
    block,
    white,
    ls,
    isFontLoaded,
    className,
    navigo,
    lineHeight,
    colored,
    url,
  } = props;

  const style = {};
  if (fontSize) style.fontSize = `${fontSize}rem`;
  if (lineHeight) style.lineHeight = `${lineHeight}rem`;

  return (
    <span
      className={cx('text', {
        'text-heavy': heavy,
        'text-bold': bold,
        'text-block': block,
        'text-italic': italic,
        'text-white': white,
        'text-ls': ls,
        'text-fallback': !isFontLoaded,
        'text-navigo': navigo,
        'text-colored': colored,
        [className]: className,
      })}
      style={style}
      // eslint-disable-next-line
      dangerouslySetInnerHTML={html ? {__html: html } : null}
    >
      {url ? (
        <a href={url}>{children}</a> 
      ) : children }
    </span>
  );
};

Text.propTypes = {
  isFontLoaded: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.element.isRequired,
    PropTypes.arrayOf(PropTypes.element.isRequired).isRequired
  ]),
  html: PropTypes.string,
  bold: PropTypes.bool,
  heavy: PropTypes.bool,
  fontSize: PropTypes.number,
  lineHeight: PropTypes.number,
  block: PropTypes.bool,
  italic: PropTypes.bool,
  white: PropTypes.bool,
  ls: PropTypes.bool,
  className: PropTypes.string,
  navigo: PropTypes.bool,
  colored: PropTypes.bool,
  url: PropTypes.string,
};

Text.defaultProps = {
  isFontLoaded: true,
  bold: false,
  heavy: false,
  fontSize: null,
  lineHeight: null,
  block: false,
  italic: false,
  white: false,
  ls: false,
  children: null,
  html: null,
  className: null,
  navigo: false,
  colored: false,
};

export default Text;
