import { connect } from 'react-redux';
import {
  ideaRepostVk,
  ideaRepostFb,
} from './ideasReducer';

const stateToProps = null;

const dispatchToProps = {
  ideaRepostVk,
  ideaRepostFb,
};

export default connect(stateToProps, dispatchToProps)(
  ({ children, ...props }) => children({ ...props }),
);
