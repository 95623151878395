import React, { Component } from "react";
import PropTypes from "prop-types";
import Text from "../Text/Text";
import Popover from "../Popover/Popover";
import Icon from "../Icon/Icon";
import RepostList, { RepostListItem } from "../RepostList/RepostList";
import Touchable from "../Touchable";
import "./likeRepost.css";
import locale from "../../locale";

export default class LikeRepost extends Component {
    static propTypes = {
        onOpenRepost: PropTypes.func,
        onCloseRepost: PropTypes.func,
        onClickVk: PropTypes.func,
        onClickFb: PropTypes.func,
        onClickLike: PropTypes.func,
        likes: PropTypes.shape({
            likes: PropTypes.number.isRequired,
            use: PropTypes.bool.isRequired,
            loaded: PropTypes.bool.isRequired
        }),
        shares: PropTypes.shape({
            fb: PropTypes.number,
            vk: PropTypes.number
        })
    };

    static defaultProps = {
        onOpenRepost: null,
        onCloseRepost: null,
        onClickVk: null,
        onClickFb: null,
        onClickLike: null,
        likes: {
            likes: 0,
            use: false,
            loaded: false
        },
        shares: null
    };

    constructor(props) {
        super(props);
        this.state = {
            isLikePopoverVisible: true,
            isRepostPopoverVisible: false,
            isRepostPopoverDisplayNode: true
        };
    }

    onMouseEnterRepost = () => {
        const { onOpenRepost } = this.props;

        if (this.hideTimeout) clearTimeout(this.hideTimeout);
        if (this.displayTimeout) clearTimeout(this.displayTimeout);
        this.setState(
            {
                isRepostPopoverVisible: false,
                isLikePopoverVisible: false,
                isRepostPopoverDisplayNode: false
            },
            () => {
                setTimeout(() => {
                    if (onOpenRepost) {
                        onOpenRepost();
                    }
                    this.setState({
                        isRepostPopoverVisible: true
                    });
                }, 0);
            }
        );
    };

    onMouseLeaveRepost = () => {
        const { onCloseRepost } = this.props;

        if (this.hideTimeout) clearTimeout(this.hideTimeout);
        if (this.displayTimeout) clearTimeout(this.displayTimeout);
        this.hideTimeout = setTimeout(() => {
            if (onCloseRepost) {
                onCloseRepost();
            }
            this.setState(
                {
                    isRepostPopoverVisible: false,
                    isLikePopoverVisible: true
                },
                () => {
                    this.displayTimeout = setTimeout(() => {
                        this.setState({
                            isRepostPopoverDisplayNode: true
                        });
                    }, 500);
                }
            );
        }, 300);
    };

    renderLikePopoverContent = () => {
        const {
            likes: { likes }
        } = this.props;
        return (
            <>
                <Icon type="heart" inlineBlock va={-0.15} />,
                <Text white fontSize={2.2}>
                    {` ${likes}`}
                </Text>
            </>
        );
    };

    renderRepostPopoverContent = () => {
        const { shares, onClickVk, onClickFb } = this.props;

        return (
            <RepostList>
                <RepostListItem key="vk">
                    <button type="button" onClick={onClickVk}>
                        <Text className="repost-list-item-social-title" heavy>
                            {locale.vk}
                        </Text>
                        <Text className="repost-list-item-social-value">{shares && shares.vk}</Text>
                    </button>
                </RepostListItem>
                <RepostListItem key="fb">
                    <button type="button" onClick={onClickFb}>
                        <Text heavy className="repost-list-item-social-title">
                            {locale.fb}
                        </Text>
                        <Text className="repost-list-item-social-value">{shares && shares.fb}</Text>
                    </button>
                </RepostListItem>
            </RepostList>
        );
    };

    render() {
        const { onClickLike, likes } = this.props;
        const { isLikePopoverVisible, isRepostPopoverVisible, isRepostPopoverDisplayNode } = this.state;
        return (
            <Touchable>
                <div className="like-repost">
                    <div className="like-repost-handler">
                        <button className="like-repost-handler-button" type="button" onClick={onClickLike}>
                            <Text heavy white>
                                {locale.like}
                            </Text>
                        </button>
                        {likes.loaded && (
                            <div className="like-repost-popover-wrapper">
                                <Popover theme="like" visible={isLikePopoverVisible}>
                                    {this.renderLikePopoverContent()}
                                </Popover>
                            </div>
                        )}
                    </div>
                    <div
                        className="like-repost-handler"
                        onMouseEnter={this.onMouseEnterRepost}
                        onMouseLeave={this.onMouseLeaveRepost}
                    >
                        <Text heavy white>
                            {locale.repost}
                        </Text>
                        <div className="like-repost-popover-wrapper">
                            <Popover visible={isRepostPopoverVisible} displayNone={isRepostPopoverDisplayNode}>
                                {this.renderRepostPopoverContent()}
                            </Popover>
                        </div>
                    </div>
                </div>
            </Touchable>
        );
    }
}
