import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const Visibility = ({ visible, children }) => (
  <div
    className={cx('visibility', {
      'visibility--visible': visible,
    })}
  >
    {children}
  </div>
);

Visibility.propTypes = {
  visible: PropTypes.bool.isRequired,

  /* eslint-disable react/forbid-prop-types */
  children: PropTypes.any.isRequired,
};

export default Visibility;
