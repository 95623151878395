import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import './idea.css';
import Text from '../Text';
import MoreIdeas from '../MoreIdeas';
import Touchable from '../Touchable';
import locale from '../../locale';

/* eslint-disable react/prefer-stateless-function */
export default class Idea extends Component {
  static propTypes = {
    isBlackBoxOpened: PropTypes.bool,
    idea: PropTypes.shape({
      id: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }).isRequired,

    animationStarted: PropTypes.bool.isRequired,
    isNext: PropTypes.bool,
    onClickNext: PropTypes.func.isRequired,

    isIndexPage: PropTypes.bool,
    isIndexAnimationStarted: PropTypes.bool,
  }

  static defaultProps = {
    isBlackBoxOpened: false,
    isNext: false,

    isIndexPage: false,
    isIndexAnimationStarted: false,
  }

  wrapByTouchable = (children) => {
    const { isNext } = this.props;
    if (isNext) return children;

    return (
      <Touchable>{children}</Touchable>
    );
  }

  render() {
    const {
      isBlackBoxOpened,
      idea,
      animationStarted,
      isNext,
      onClickNext,
      isIndexPage,
      isIndexAnimationStarted,
    } = this.props;
    const {
      image,
      text,
      id,
    } = idea;

    const backgroundStyle = {
      background: `url(${image})`,
      backgroundSize: '100% 100%',
    };

    return (
      <div
        className={cx('idea', {
          'idea--is-next': isNext,
          'idea--animation-center-left': animationStarted && !isNext,
          'idea--animation-right-center': animationStarted && isNext,
          'idea--is-black-box-opened': isBlackBoxOpened,
          'idea--is-index-page': isIndexPage,
          'idea--is-index-animation-started': isIndexAnimationStarted,
        })}
      >
        <div className="idea-background" style={backgroundStyle} />
        <div className="idea-border">
          <div className="idea-wrapper">
            {this.wrapByTouchable(
              <>
                <div className="idea-title">
                  <Text bold>
                    {`${locale.num}${id}`}
                  </Text>
                </div>
                <div className="idea-text">
                  <Text html={text} />
                </div>
                <div className="idea-button">
                  {!isBlackBoxOpened && (
                    <MoreIdeas small onClick={onClickNext}>
                      <Text bold white>
                        {locale.more}
                      </Text>
                    </MoreIdeas>
                  )}
                  {isBlackBoxOpened && (
                    <MoreIdeas small smallWhite onClick={onClickNext}>
                      <Text bold>
                        {locale.moreIdeas}
                      </Text>
                    </MoreIdeas>
                  )}
                </div>
              </>,
            )}
          </div>
        </div>
      </div>
    );
  }
}
