import ideas from "./ideas";

const url = "https://giveaway.nimax.ru";

const locale = {
    brandName: "Nimax",
    instagram: "@nimax_agency",
    seeMore: "See more",
    instagramHref: "https://www.instagram.com/Nimax_agency/",
    orderHref: "https://nimax.ru/order/",

    downloadHref: "/poster.pdf",

    title: "Генератор конкурсов от Nimax",

    og: {
        index: {
            title: "Генератор конкурсов для соцсетей — Nimax",
            description: "Получи идею для конкурса в соцсетях. Даром. Giveaway. За репост",
            type: "website",
            url: `${url}/`,
            image: "https://giveaway.nimax.ru/cover.png"
        },
        blackBox: {
            url: `${url}/`
        }
    },

    lines: [
        { text: "Не хотите придумывать сами,", fontSize: 1.24 },
        { text: "обращайтесь в", fontSize: 2.44 },
        { text: "Nimax", fontSize: 6.04, highlighted: true },
        { text: "мы все устроим", fontSize: 2.3 },
        { text: "от 150 тыс.", fontSize: 3.45 },
        { text: "рублей в месяц", fontSize: 2.37 }
    ],
    shareIdeaLines: [{ text: "ПОШАРИТЬ", fontSize: 2.304 }, { text: "ИДЕЮ", fontSize: 4.456, highlighted: true }],
    moreIdeas: "Еще идеи",
    moreIdeasLarge: "Получить",
    moreIdeasLargeSubtitle: "идею",
    ideas,
    more: "Еще",
    vk: "ВКонтакте ",
    fb: "Фейсбук ",
    submit: "ОСТАВИТЬ ЗАЯВКУ",
    description: "Лучшие&nbsp;идеи<br /> топового<br />агентства.<br />Даром.<br />GIVEAWAY.<br />За&nbsp;репост!",
    like: "Лайк",
    num: "Идея №",
    repost: "Репост",
    rangeTitle: "Как вам идея?",
    h1FirstLine: "генератор",
    h1SecondLine: "конкурсов",
    blackBox: {
        title:
            "Классная идея — это начало. Чтобы ничего не упустить в организации конкурса, сверьтесь с нашим чеклистом.",
        downloadText: "Чтобы скачать наш чеклист — <br />поделитесь проектом:",
        menu: [
            { title: "Наш сайт", href: "https://nimax.ru" },
            { title: "ВКонтакте", href: "https://vk.com/nimaxru" },
            { title: "Фейсбук", href: "https://www.facebook.com/nimax.ru" },
            { title: "Инстаграм", href: "https://www.instagram.com/Nimax_agency/" }
        ],
        orderText: "Или доверьте создание и проведение конкурса нам —<br /> мы на этом Бузову съели.",
        download: "Скачать"
    }
};

export { url };
export default locale;
