import { all } from 'redux-saga/effects';
import {
  loaderSaga,
  ideasRootSaga,
  repostRootSaga,
  likesRootSaga,
} from './components';
import isClient from './isClient';

export default function* rootSaga() {
  yield all(
    isClient
      ? [
        loaderSaga(),
        ideasRootSaga(),
        repostRootSaga(),
        likesRootSaga(),
      ]
      : [],
  );
}
