const ideas = [
  {
    id: '1',
    image: 'pic-1.jpg',
    text: 'Кулинарный марафон: неделю готовить по&nbsp;вашим рецептам и&nbsp;выкладывать фотопруфы. Тому, кто&nbsp;продержался, сертификат на&nbsp;недельную доставку еды&nbsp;+ ваш продукт (если хотите).'
  },
  {
    id: '2',
    image: 'pic-2.jpg',
    text: 'Рассказать о&nbsp;примете/ритуале, которые человек соблюдает. В&nbsp;подарок самому суеверному&nbsp;&mdash; консультация у&nbsp;знаменитого астролога.',
  },
  {
    id: '3',
    image: 'pic-3.jpg',
    text: 'Ко&nbsp;Дню Космонавтики: выложить фотографию своего кота в&nbsp;самодельном костюме астронавта. Поход в Планетарий и&nbsp;книга про&nbsp;космос самому фантастическому.',
  },
  {
    id: '4',
    image: 'pic-4.jpg',
    text: 'Заснять в&nbsp;сториз своё исполнение песни из&nbsp;мюзикла. Приз&nbsp;&mdash; билеты в&nbsp;театр на&nbsp;мюзикл. Возможно, конкурс в&nbsp;партнёрстве с&nbsp;театром',
  },
  {
    id: '5',
    image: 'pic-5.jpg',
    text: 'Конкурс на&nbsp;лучший короткий фанфик&nbsp;&mdash; чтобы поместился в&nbsp;пост Инстаграма. Приз: подписка на&nbsp;Букмейт.',
  },
  {
    id: '6',
    image: 'pic-6.jpg',
    text: 'Начитать в&nbsp;сториз своё любимое стихотворение. Лучшему исполнителю&nbsp;&mdash; урок актёрского мастерства.',
  },
  {
    id: '7',
    image: 'pic-7.jpg',
    text: 'Выложить фотографию самого трешово украшенного двора (мягкие игрушки, лебеди&nbsp;&mdash; такое). Приз&nbsp;&mdash; билеты на&nbsp;выставку.',
  },
  {
    id: '8',
    image: 'pic-8.jpg',
    text: 'Поделиться своей самой дурацкой детской фотографией (с&nbsp;лемуром, в&nbsp;костюме императрицы&nbsp;&mdash; такое) и&nbsp;рассказать историю её&nbsp;создания. Приз&nbsp;&mdash; абонемент на&nbsp;батуты. ',
  },
  {
    id: '9',
    image: 'pic-9.jpg',
    text: 'Выложить фотографию своего бардака. Хозяину бардака всех бардаков&nbsp;&mdash; сертификат на&nbsp;уборку.',
  },
  {
    id: '10',
    image: 'pic-10.jpg',
    text: 'Конкурс для&nbsp;начинающих музыкантов: выложить видео своего выступления. Приз&nbsp;&mdash; запись сингла в&nbsp;студии.',
  },
  {
    id: '11',
    image: 'pic-11.jpg',
    text: 'Придумать и&nbsp;набросать эскиз мерча для&nbsp;вашей компании. Автора лучшего награждайте, а&nbsp;мерч отправляйте в&nbsp;производство.',
  },
  {
    id: '12',
    image: 'pic-12.jpg',
    text: 'Рассказать трогательную семейную историю и&nbsp;отметить под&nbsp;постом маму (очень благодарная аудитория, которая поднимет вовлечение до&nbsp;небес). Подарок&nbsp;&mdash; семейная фотосессия.',
  },
  {
    id: '13',
    image: 'pic-13.jpg',
    text: 'Поделиться фотографией и&nbsp;рецептом домашнего рамена. Приз&nbsp;&mdash; сертификат в&nbsp;магазин японских продуктов.',
  },
  {
    id: '14',
    image: 'pic-14.jpg',
    text: 'Поделиться своей экологичной привычкой и&nbsp;процессом её прививания. В&nbsp;подарок сертификат в&nbsp;магазин эко-товаров.',
  },
  {
    id: '15',
    image: 'pic-15.jpg',
    text: 'Набросать эскиз самых уродливых кроссовок и&nbsp;объяснить, почему они будут в&nbsp;тренде уже вот-вот. Подарить сертификат в&nbsp;Брендшоп.',
  },
  {
    id: '16',
    image: 'pic-16.jpg',
    text: 'Пасхально-яичная угадайка в&nbsp;Инстаграме. В&nbsp;карусели картинки нескольких яиц&nbsp;&mdash; нужно в&nbsp;комментариях поставить на&nbsp;победителя в&nbsp;битках (битве на&nbsp;яйцах). Среди угадавших разыграть набор дорогущих пищевых красителей.',
  },
  {
    id: '17',
    image: 'pic-17.jpg',
    text: 'Рассказать про&nbsp;знакомство со&nbsp;своим партнёром. Приз&nbsp;&mdash; сертификат в&nbsp;гончарную мастерскую. Как в&nbsp;фильме «Призрак».',
  },
  {
    id: '18',
    image: 'pic-18.jpg',
    text: 'Рассказать про&nbsp;любимую детскую книгу. Приз&nbsp;&mdash; подарочное издание этой книги&nbsp;/ сертификат в&nbsp;книжный.',
  },
  {
    id: '19',
    image: 'pic-19.jpg',
    text: 'Рассказать, концовку какого популярного произведения человек бы изменил, и&nbsp;как именно. Приз&nbsp;&mdash; курс писательского мастерства Андрея Аствацатурова.',
  },
  {
    id: '20',
    image: 'pic-20.jpg',
    text: 'Поделиться неочевидным велосипедным маршрутом своего города. Дарить вело-навигатор или&nbsp;сертификат на&nbsp;обслуживание велосипеда.',
  },
  {
    id: '21',
    image: 'pic-21.jpg',
    text: 'Рассказать жуткую историю своей спортивной травмы&nbsp;&mdash; с&nbsp;кроваво-эпичными подробностями. За&nbsp;самую крутую историю&nbsp;&mdash; набор защитной экипировки.',
  },
  {
    id: '22',
    image: 'pic-22.jpg',
    text: 'Остроумно рассказать о&nbsp;своём одиночестве. Подарок&mdash; умная станция Алиса от&nbsp;Яндекса. Возможно, конкурс для&nbsp;Твиттера (там&nbsp;принято ныть).',
  },
  {
    id: '23',
    image: 'pic-23.jpg',
    text: 'Рассказать трогательную/позорную историю про&nbsp;свою школьную дискотеку. Дарить большой дискошар.',
  },
  {
    id: '24',
    image: 'pic-24.jpg',
    text: 'Придумать канал на&nbsp;Ютубе/подкаст, которого сейчас не&nbsp;хватает. В&nbsp;подарок&nbsp;&mdash; микрофон.',
  },
  {
    id: '25',
    image: 'pic-25.jpg',
    text: 'Рассказать о&nbsp;самом ужасном подарке, который человек получал на&nbsp;8&nbsp;марта&nbsp;/ 23&nbsp;февраля. Дарить подписку на&nbsp;Нетфликс.',
  },
  {
    id: '26',
    image: 'pic-26.jpg',
    text: 'Конкурс ко&nbsp;дню отца/матери. Поделиться, какое знание/мудрость, полученное от&nbsp;родителя, человек обязательно передаст своему ребенку. Дарить детские курсы.',
  },
  {
    id: '27',
    image: 'pic-27.jpg',
    text: 'Оставить короткую рецензию на&nbsp;любимый фильм 80-х. Получить билеты на&nbsp;показ «Иное кино».',
  },
  {
    id: '28',
    image: 'pic-28.jpg',
    text: 'Конкурс-челлендж&nbsp;&mdash; серия публикаций с&nbsp;иллюстрациями созвездий. Тому, кто угадает все&nbsp;&mdash; телескоп/абонемент в&nbsp;Планетарий.',
  },
  {
    id: '29',
    image: 'pic-1.jpg',
    text: 'Сфотографировать интересную птицу в&nbsp;своём городе. Дарить сертификат в&nbsp;кофейню с&nbsp;птичьим названием. ',
  },
  {
    id: '30',
    image: 'pic-2.jpg',
    text: 'Челлендж: серия сториз с&nbsp;малоизвестными картинами. Тому, кто первым правильно назовёт всех авторов, абонемент в&nbsp;музей. Возможно, конкурс вместе с&nbsp;музеем. ',
  },
  {
    id: '31',
    image: 'pic-3.jpg',
    text: 'Челлендж на&nbsp;сутки: игра в&nbsp;слова в&nbsp;комментариях. Человек называет диалектизм, распространённый в&nbsp;его городе. Следующий комментатор пытается угадать, что это такое (и&nbsp;может предложить своё слово). Тому, кто за&nbsp;сутки отгадает больше всего слов, в&nbsp;подарок билеты на&nbsp;фестиваль.',
  },
  {
    id: '32',
    image: 'pic-4.jpg',
    text: 'Рассказать, какой фильм впечатлил и&nbsp;подтолкнул к&nbsp;изменениям. Приз&nbsp;&mdash; афиша этого фильма и&nbsp;билеты в&nbsp;кино. Конкурс вместе с&nbsp;типографией/магазином постеров.',
  },
  {
    id: '33',
    image: 'pic-5.jpg',
    text: 'Сделать самое обычное фото из&nbsp;всех возможных. Максимально обычному&nbsp;&mdash; сертификат в&nbsp;магазин бланковой одежды.',
  },
  {
    id: '34',
    image: 'pic-6.jpg',
    text: 'Выложить в&nbsp;сториз свое «исполнение» какой-то песни&nbsp;&mdash; беззвучное караоке! Самому артистичному&nbsp;&mdash; билет на&nbsp;концерт.',
  },
  {
    id: '35',
    image: 'pic-7.jpg',
    text: 'Посоветовать блогера, с&nbsp;которым вашей компании обязательно нужно посотрудничать. Тому, кто попадёт в&nbsp;точку, дарить образовательный диджитал-курс (ИКРа, Skillbox).',
  },
  {
    id: '36',
    image: 'pic-8.jpg',
    text: 'Придумать, кому из&nbsp;исторических персонажей мог бы помочь ваш продукт в&nbsp;сложной ситуации. Победителю&nbsp;&mdash; сертификат в PYE.',
  },
  {
    id: '37',
    image: 'pic-9.jpg',
    text: 'Прислать в&nbsp;комментарии скриншот с&nbsp;вашим продуктом в&nbsp;сериале «Друзья». Среди всех участников рандомно найти победителя и&nbsp;подарить ему толстовку с&nbsp;героями сериала.',
  },
  {
    id: '38',
    image: 'pic-10.jpg',
    text: 'Отметить в&nbsp;комментариях двух зеленоглазых друзей. Среди всех участников рандомно найти победителя и&nbsp;вручить матча чай.',
  },
  {
    id: '39',
    image: 'pic-11.jpg',
    text: 'Придумать смешной хэштег, который вы обязательно будете использовать в&nbsp;следующем месяце. Автору&nbsp;&mdash; билеты на&nbsp;стэндап.',
  },
  {
    id: '40',
    image: 'pic-12.jpg',
    text: 'Придумать небанальный вопрос для&nbsp;анкеты об&nbsp;улучшении качества обслуживания.',
  },
  {
    id: '41',
    image: 'pic-13.jpg',
    text: 'Поделиться подслушанной в&nbsp;общественном месте историей. Таргетируйтесь на&nbsp;подписчиков «Подслушано». Автору классной истории&nbsp;&mdash; наушники.',
  },
  {
    id: '42',
    image: 'pic-14.jpg',
    text: 'Выбрать что-то на&nbsp;специальном лендинге с&nbsp;подарками. Запостить себе на&nbsp;стену и&nbsp;ждать розыгрыша.',
  },
  {
    id: '43',
    image: 'pic-15.jpg',
    text: 'Сфотографировать себя или&nbsp;что-то из&nbsp;интерьера в&nbsp;вашем магазине/заведении ради скидки/небольшого подарка.',
  },
  {
    id: '44',
    image: 'pic-16.jpg',
    text: 'Придумать самое интересное применение коробке/упаковочной бумаге от&nbsp;вашего продукта. Победителю продукт. Сработает, если упаковка красивая.',
  },
  {
    id: '45',
    image: 'pic-17.jpg',
    text: 'Задать самый заковыристый вопрос о&nbsp;вашем продукте. Автору самого заковыристого&nbsp;&mdash; экскурсия на&nbsp;ваше производство. Сработает, если там интересно.',
  },
  {
    id: '46',
    image: 'pic-18.jpg',
    text: 'Рассказать, что человеку хотелось иметь в&nbsp;детстве, но&nbsp;родители так и&nbsp;не купили. Самое интересное желание исполняйте.',
  },
  {
    id: '47',
    image: 'pic-19.jpg',
    text: 'SFS: опубликовать в&nbsp;сториз любой пост вашей компании с&nbsp;призывом подписаться на&nbsp;аккаунт. В&nbsp;ответ компания выложит фотографии победителей к&nbsp;себе в&nbsp;сториз (сработает, если ваш аккаунт популярный).',
  },
  {
    id: '48',
    image: 'pic-20.jpg',
    text: 'Придумать описание для&nbsp;предложенной картинки в&nbsp;духе музейных экскурсоводов. Самому убедительному&nbsp;&mdash; сертификат в&nbsp;музей.',
  },
  {
    id: '49',
    image: 'pic-21.jpg',
    text: 'Совместный с&nbsp;блогером конкурс-квест. Блогер прячет сертификат на&nbsp;ваш продукт в&nbsp;нескольких местах города и&nbsp;объявляет об&nbsp;этом подписчикам. Они бегут искать!',
  },];

module.exports = ideas;
