import React, { Component } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { DraggableCore } from 'react-draggable';
import Text from '../Text/Text';
import Icon from '../Icon/Icon';
import locale from '../../locale';
import './rangeSlider.css';

const startP = 3;
const endP = 7;

const fixPosition = position => position / 100 * (100 - startP - endP) + startP;

class RangeSlider extends Component {
  static propTypes = {
    position: PropTypes.number,
    size: PropTypes.number,
    onStart: PropTypes.func.isRequired,
    onDrag: PropTypes.func.isRequired,
    onStop: PropTypes.func.isRequired,
    onMouseEnter: PropTypes.func.isRequired,
    storeRef: PropTypes.func.isRequired,
    emoji: PropTypes.string.isRequired,
    isDraggingNow: PropTypes.bool,
  };

  static defaultProps = {
    position: 80,
    size: 1,
    isDraggingNow: false,
  };

  render() {
    const {
      position,
      size,
      onStart,
      onDrag,
      onStop,
      onMouseEnter,
      storeRef,
      emoji,
      isDraggingNow,
    } = this.props;

    return (
      <div
        onMouseEnter={onMouseEnter}
        className="range-slider"
      >
        <Text fontSize={1.4} bold>
          {locale.rangeTitle}
        </Text>
        <div
          className="range-slider-bar"
          ref={storeRef}
        >
          <div
            className="range-slider-bar-progress-bg"
          />
          <div
            className="range-slider-bar-progress"
            style={{ width: `${100 - position}%` }}
          />
          <DraggableCore
            onStart={onStart}
            onDrag={onDrag}
            onStop={onStop}
          >
            <div
              style={{ left: `${fixPosition(position)}%` }}
              className={cx('range-slider-bar-handler', {
                'range-slider-bar-handler--isDraggingNow': isDraggingNow,
              })}
            >
              <Icon
                animationSize={!isDraggingNow}
                type={emoji}
                inlineBlock
                scale={size}
              />
            </div>
          </DraggableCore>
        </div>
      </div>
    );
  }
}

export default RangeSlider;
