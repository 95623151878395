import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import Touchable from "../Touchable";
import "./button.css";

const Button = ({ children, theme, onClick, to }) => {
    let TagButton = "button";
    let attrs = {};

    if (to) {
        TagButton = "a";
        attrs.href = to;
    } else {
        attrs.type = "button";
    }

    return (
        <Touchable>
            <TagButton
                className={cx("button", {
                    [`button-theme--${theme}`]: theme
                })}
                onClick={onClick}
                {...attrs}
            >
                <div className="button__wrapper">
                    <div className="button__inner">{children}</div>
                </div>
            </TagButton>
        </Touchable>
    );
};

Button.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.element.isRequired,
        PropTypes.arrayOf(PropTypes.element.isRequired).isRequired
    ]).isRequired,
    theme: PropTypes.string,
    onClick: PropTypes.func,
    to: PropTypes.string
};

Button.defaultProps = {
    theme: "default",
    onClick: null
};

export default Button;
