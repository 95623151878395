import { Component } from 'react';
import PropTypes from 'prop-types';
import clamp from 'lodash/clamp';

const defaultIdeaRange = {
  value: 0,
};

class RangeSliderDataProvider extends Component {
  static propTypes = {
    children: PropTypes.func.isRequired,
    ideaRange: PropTypes.shape({
      value: PropTypes.number.isRequired,
    }),
    userSetIdeaRange: PropTypes.func.isRequired,
    isNext: PropTypes.bool,
  };

  static defaultProps = {
    ideaRange: defaultIdeaRange,
    isNext: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      position: props.ideaRange.value,
      // (esilint bug, i use it in this.getPosition as default agr)
      // eslint-disable-next-line
      x: 0,
      alreadyDragged: false,
    };
  }

  componentDidMount() {
    const { isNext } = this.props;
    if (!isNext) {
      this.timeout = setTimeout(this.onMouseEnter, 3000);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { ideaRange } = this.props;
    if (
      nextProps.ideaRange !== ideaRange
      && ideaRange === defaultIdeaRange
    ) {
      this.setState({
        position: nextProps.ideaRange.value,
      });
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  onStart = (event, { x }) => {
    clearTimeout(this.timeout);
    const { alreadyDragged } = this.state;
    if (alreadyDragged) return;

    this.barWidth = this.ref.getBoundingClientRect().width;
    this.initialX = x;
  };

  onDrag = (event, { x }) => {
    const { alreadyDragged } = this.state;
    if (alreadyDragged) return;

    this.setState({
      // eslint-disable-next-line
      x,
    });
  };

  onStop = (event, { x }) => {
    const { alreadyDragged } = this.state;
    if (alreadyDragged) return;

    const { userSetIdeaRange } = this.props;
    this.setState({
      // eslint-disable-next-line
      x: null,
      position: this.getPosition({ x }),
      alreadyDragged: true,
    }, () => {
      const { position } = this.state;
      userSetIdeaRange(position);
    });
    this.initialX = null;
    this.barWidth = null;
  };

  getPosition = ({ x } = this.state) => {
    const { position } = this.state;

    const isDraggingNow = x;
    if (isDraggingNow) {
      const offsetFromInitialX = x - this.initialX;
      const pixelsInPercent = this.barWidth / 100;
      return clamp(
        position + (offsetFromInitialX / pixelsInPercent),
        0,
        100,
      );
    }

    return position;
  };

  onMouseEnter = () => {
    clearTimeout(this.timeout);
    const { alreadyDragged } = this.state;
    if (alreadyDragged) return;

    this.setState({
      position: 0,
    });
  };

  storeRef = (ref) => {
    this.ref = ref;
  };

  render() {
    const { children } = this.props;
    const { x } = this.state;
    const position = this.getPosition();
    return children({
      onStart: this.onStart,
      onDrag: this.onDrag,
      onStop: this.onStop,
      onMouseEnter: this.onMouseEnter,
      isDraggingNow: !!x,
      position,
      size: 0.8 + (0.4 * position / 100),
      storeRef: this.storeRef,
    });
  }
}

export default RangeSliderDataProvider;
