import React from 'react';
import locale from '../../locale';
import Text from '../Text';
import Touchable from '../Touchable';

const BlackBoxTitle = () => (
  <div className="blackBoxTitle">
    <Touchable>
      <Text navigo fontSize={2.9} white heavy lineHeight={4.2}>
        {locale.blackBox.title}
      </Text>
    </Touchable>
  </div>
);

export default BlackBoxTitle;
