import { connect } from 'react-redux';
import { selector as isAppLoaded } from './loaderReducer';

export default connect(
  state => ({
    visible: !isAppLoaded(state),
  }),
)(
  ({ children, ...props }) => children({ ...props }),
);
