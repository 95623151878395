import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import Text from '../Text/Text';
import locale from '../../locale';
import Touchable from '../Touchable';
import './description.css';

const Description = ({ storeRef, scale, hidden }) => (
  <Touchable>
    <div
      className={cx('description', {
        'description--hidden': hidden,
      })}
      ref={storeRef}
      style={{
        transform: `scale(${scale}) rotate(14deg)`,
      }}
    >
      <Text white html={locale.description} />
    </div>
  </Touchable>
);

Description.propTypes = {
  storeRef: PropTypes.func,
  scale: PropTypes.number,
  hidden: PropTypes.bool,
};

Description.defaultProps = {
  storeRef: null,
  scale: 1,
  hidden: false,
};

export default Description;
