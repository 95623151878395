import { connect } from 'react-redux';
import {
  getIndexLikes,
  getIndexShares,
  likeIndex,
} from './likesReducer';

const stateToProps = state => ({
  indexLikes: getIndexLikes(state),
  indexShares: getIndexShares(state),
});

const dispatchToProps = {
  likeIndex,
};

export default connect(stateToProps, dispatchToProps)(
  ({ children, ...props }) => children({ ...props }),
);
