import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import './layout.css';
import './layoutPhone.css';
import './layoutTablet.css';
import './layoutDesktop.css';

const pt = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.element,
    ).isRequired,
    PropTypes.element,
    PropTypes.string,
  ]).isRequired,
  storeRef: PropTypes.func,
};

const dpt = {
  storeRef: null,
};

const Debug = ({ title }) => (
  <div className="layoutDebug">
    {title}
  </div>
);

const Scalable = ({ children, scale }) => {
  const style = {};
  if (scale) style.transform = `scale(${scale})`;

  return (
    <div className="layoutScalable" style={style}>
      {children}
    </div>
  );
};

const RangeSliderWrapper = ({
  children,
  storeRef,
  isBlackBoxOpened,
  isNext,
  animationStarted,

  isIndexPage,
  isIndexAnimationStarted,
}) => (
  <div
    ref={storeRef}
    className={cx('layoutRangeSliderWrapper', {
      'layoutRangeSliderWrapper--isBlackBoxOpened': isBlackBoxOpened,
      'layoutRangeSliderWrapper--is-next': isNext,
      'layoutRangeSliderWrapper--animation-center-bottom': animationStarted && !isNext,
      'layoutRangeSliderWrapper--animation-bottom-center': animationStarted && isNext,

      'layoutRangeSliderWrapper--is-index-page': isIndexPage,
      'layoutRangeSliderWrapper--is-index-animation-started': isIndexAnimationStarted,
    })}
  >
    <div className="layoutRangeSliderWrapper-inner">
      {children}
    </div>
  </div>
);

const TopLeft = ({ children, storeRef, zIndex }) => {
  const style = {};
  if (zIndex) style.zIndex = zIndex;
  return (
    <div className="layoutTopLeft" ref={storeRef} style={style}>
      {children}
    </div>
  );
};

const TopLeftQuarter = ({ children, storeRef }) => (
  <div className="layoutTopLeftQuarter" ref={storeRef}>
    <div className="layoutTopLeftQuarter-content">
      {children}
    </div>
  </div>
);

const BottomRightQuarter = ({
  children,
  storeRef,
  center,
  bottomRight,
  animation,
}) => (
  <div
    className={cx('layoutBottomRightQuarter', {
      center,
      bottomRight,
      'layoutBottomRightQuarter--animation': animation,
    })}
    ref={storeRef}
  >
    <div className="layoutBottomRightQuarter-content">
      {children}
    </div>
  </div>
);

const BottomLeftQuarter = ({ children, storeRef }) => (
  <div className="layoutBottomLeftQuarter" ref={storeRef}>
    <div className="layoutBottomLeftQuarter-content">
      {children}
    </div>
  </div>
);

const TopRightQuarter = ({ children, storeRef }) => (
  <div className="layoutTopRightQuarter" ref={storeRef}>
    <div className="layoutTopRightQuarter-content">
      {children}
    </div>
  </div>
);

const BottomLeft = ({
  children,
  storeRef,
  animation,
  zIndex,
}) => {
  const style = {};
  if (zIndex) style.zIndex = zIndex;
  return (
    <div
      className={cx('layoutBottomLeft', {
        'layoutBottomLeft--animation': animation,
      })}
      ref={storeRef}
      style={style}
    >
      {children}
    </div>
  );
};


const BottomRight = ({ children, storeRef }) => (
  <div className="layoutBottomRight" ref={storeRef}>
    {children}
  </div>
);

const CenterCenter = ({
  children,
  storeRef,
  padded,
  zIndex,
}) => (
  <div className="layoutCenterCenter" ref={storeRef}>
    <div
      className={cx(
        'layoutCenterCenter-content', {
          'layoutCenterCenter-content--padded': padded,
          'layoutCenterCenter-content--zIndex': zIndex,
        },
      )}
    >
      {children}
    </div>
  </div>
);

const Container = ({ children, storeRef, isBlackBoxOpened }) => (
  <div
    className={cx('layout', {
      'layout--is-black-box-opened': isBlackBoxOpened,
    })}
    ref={storeRef}
  >
    {children}
  </div>
);

RangeSliderWrapper.propTypes = {
  ...pt,
  isBlackBoxOpened: PropTypes.bool.isRequired,
};
RangeSliderWrapper.defaultProps = dpt;

Container.propTypes = {
  ...pt,
  isBlackBoxOpened: PropTypes.bool,
};
Container.defaultProps = {
  ...dpt,
  isBlackBoxOpened: false,
};

TopLeft.propTypes = {
  ...pt,
  zIndex: PropTypes.number,
};
TopLeft.defaultProps = {
  ...dpt,
  zIndex: null,
};

BottomLeft.propTypes = {
  ...pt,
  animation: PropTypes.bool,
};
BottomLeft.defaultProps = {
  ...dpt,
  animation: false,
};

BottomRight.propTypes = pt;
BottomRight.defaultProps = dpt;

CenterCenter.propTypes = {
  ...pt,
  padded: PropTypes.bool,
  zIndex: PropTypes.bool,
};
CenterCenter.defaultProps = {
  ...dpt,
  padded: false,
  zIndex: false,
};

TopLeftQuarter.propTypes = pt;
TopLeftQuarter.defaultProps = dpt;

BottomLeftQuarter.propTypes = pt;
BottomLeftQuarter.defaultProps = dpt;

BottomRightQuarter.propTypes = {
  ...pt,
  center: PropTypes.bool,
  bottomRight: PropTypes.bool,
  animation: PropTypes.bool,
};
BottomRightQuarter.defaultProps = {
  ...dpt,
  center: true,
  bottomRight: false,
  animation: false,
};

TopRightQuarter.propTypes = pt;
TopRightQuarter.defaultProps = dpt;

Scalable.propTypes = {
  ...pt,
  scale: PropTypes.number,
};
Scalable.defaultProps = {
  ...dpt,
  scale: null,
};

Debug.propTypes = {
  title: PropTypes.string.isRequired,
};

export {
  TopLeft,
  BottomLeft,
  BottomRight,
  Container,
  Debug,
  CenterCenter,
  TopLeftQuarter,
  BottomRightQuarter,
  BottomLeftQuarter,
  TopRightQuarter,
  Scalable,
  RangeSliderWrapper,
};
