import { connect } from 'react-redux';
import {
  getNextIdea,
  getCurrentIdea,
  showNextIdea,
  getAnimationStarted,
} from './ideasReducer';

const stateToProps = state => ({
  currentIdea: getCurrentIdea(state),
  nextIdea: getNextIdea(state),
  animationStarted: getAnimationStarted(state),
});

const dispatchToProps = {
  showNextIdea,
};

export default connect(stateToProps, dispatchToProps)(
  ({ children, ...props }) => children({ ...props }),
);
