import React from 'react';
// import locale from '../../locale';
// import Text from '../Text';
import Touchable from '../Touchable';
import image from './poster.png';

const BlackBoxPoster = () => (
  <div className="blackBoxPoster">
    <Touchable>
      <img src={image} alt="Poster" />
    </Touchable>
  </div>
);

export default BlackBoxPoster;
