import { connect } from 'react-redux';
import {
  getIdeaRange,
  userSetIdeaRange,
} from './likesReducer';

const stateToProps = (_, props) => state => ({
  ideaRange: getIdeaRange(state, props.idea.id),
});

const dispatchToProps = {
  userSetIdeaRange,
};

export default connect(stateToProps, dispatchToProps)(
  ({ children, ...props }) => children({
    ...props,
    userSetIdeaRange: (...args) => props.userSetIdeaRange(props.idea.id, ...args),
  }),
);
