import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Text from '../Text';
import Touchable from '../Touchable';
import './nimaxPromo.css';
import locale from '../../locale';

const Wrapper = ({ children, white }) => (
  <div
    className={
      cx('nimax-promo-highlighter', {
        'nimax-promo-highlighter--white': white,
      })
    }
  >
    {children}
  </div>
);

Wrapper.propTypes = {
  children: PropTypes.element.isRequired,
  white: PropTypes.bool,
};

Wrapper.defaultProps = {
  white: false,
};

const EmptyComponent = ({ children }) => children;

const NimaxPromo = ({
  localeProp,
  margin,
  white,
  hiddenOnPhone,
  hiddenOnTablet,
  hidden,
  onClick,
}) => (
  <Touchable>
    <a
      className={cx(
        'nimax-promo', {
          'nimax-promo--hidden': hidden,
        },
      )}
      href={locale.orderHref + '?from=giveaway_page_idea'}
    >
      <div
        className={cx('nimax-promo-wrapper', { hiddenOnPhone, hiddenOnTablet })}
        style={margin ? { margin } : {}}
      >
        {locale[localeProp].map(({ text, fontSize, highlighted, url }) => {
          const Component = highlighted ? Wrapper : EmptyComponent;
          return (
            <Component key={text} white={white}>
              <Text
                white={!white && highlighted}
                fontSize={fontSize}
                heavy
                italic
                url={url}
              >
                {text}
              </Text>
            </Component>
          );
        })}
      </div>
    </a>
  </Touchable>
);

NimaxPromo.propTypes = {
  localeProp: PropTypes.oneOf([
    'lines',
    'shareIdeaLines',
  ]),
  margin: PropTypes.string,
  white: PropTypes.bool,
  hiddenOnPhone: PropTypes.bool,
  hiddenOnTablet: PropTypes.bool,
  hidden: PropTypes.bool,
  onClick: PropTypes.func,
};

NimaxPromo.defaultProps = {
  localeProp: 'lines',
  margin: null,
  white: false,
  hiddenOnPhone: false,
  hiddenOnTablet: false,
  hidden: false,
  onClick: null,
};

export default NimaxPromo;
