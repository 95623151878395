import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import iconTypes from './iconTypes';
import './icon.css';
import './icons.css';

const Icon = ({
  type,
  inlineBlock,
  va,
  margin,
  scale,
  hidden,
  animationSize,
}) => {
  const style = {};

  if (va) style.verticalAlign = `${va}rem`;
  if (margin) style.margin = margin;
  if (scale) style.transform = `scale(${scale})`;

  return (
    <span
      className={cx(
        'icon', {
          'icon--hidden': hidden,
          'icon--animationSize': animationSize,
          'icon-inline-block': inlineBlock,
          [`icon--${type}`]: true,
        },
      )}
      style={style}
    />
  );
};

Icon.propTypes = {
  type: PropTypes.oneOf(iconTypes).isRequired,
  inlineBlock: PropTypes.bool,
  va: PropTypes.number,
  margin: PropTypes.string,
  scale: PropTypes.number,
  hidden: PropTypes.bool,
  animationSize: PropTypes.bool,
};

Icon.defaultProps = {
  inlineBlock: false,
  va: 0,
  margin: null,
  scale: null,
  hidden: false,
  animationSize: false,
};

export { iconTypes };
export default Icon;
