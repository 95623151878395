import flow from "lodash/flow";
import { put, select, takeEvery } from "redux-saga/effects";
import { makeIdeaLink } from "../Ideas/ideasReducer";
import isClient from "../../isClient";
import locale, { url } from "../../locale";

const apiUrl = "https://api.giveaway.dev.nimax.ru";
const api = {
    setIndexLike: `${apiUrl}/api/like/site`,
    getIndexLike: `${apiUrl}/api/like/site`,
    getIdeaLike: id => `${apiUrl}/api/like/idea/${id}`,
    setIdeaLike: id => `${apiUrl}/api/like/idea/${id}`,
    getIdeaRange: id => `${apiUrl}/api/value/${id}`,
    setIdeaRange: (id, value) => `${apiUrl}/api/value/${id}/${value}`,

    getFbShareCount: url =>
        `https://api.sharedcount.com/v1.0/?url=${url}&apikey=caeee0d2b90b71dfc83601562906a15cd3b49807`,
    getVkShareCount: (url, index) => `https://vk.com/share.php?act=count&index=${index}&url=${url}`
};

const makeIdeaShareLink = (...args) => `${url}${makeIdeaLink(...args)}`;

// Gatsy are you kidding me?
const superagent = isClient ? require("axios") : () => {};

const t = {
    fetchIdeaLikes: "fetchIdeaLikes",
    fetchIdeaRange: "fetchIdeaRange",
    likeIndex: "likeIndex",
    likeIdea: "likeIdea",
    updateIndexLikes: "updateIndexLikes",
    updateIdeaLikes: "updateIdeaLikes",
    updateIdeaRange: "updateIdeaRange",
    incIndexLikes: "incIndexLikes",
    incIdeaLikes: "incIdeaLikes",
    userSetIdeaRange: "userSetIdeaRange",
    setIndexShareFb: "setIndexShareFb",
    setIdeaShareFb: "setIdeaShareFb",
    setShareVk: "setShareVk",
    fetchIdeaShares: "fetchIdeaShares"
};

const getStateBranch = state => state.likesReducer;

export const getIndexLikes = flow([getStateBranch, ({ index }) => index]);

export const getIndexShares = flow([getStateBranch, ({ indexShares }) => indexShares]);

export const getIdeaLikes = (state, id) => {
    const { ideas } = getStateBranch(state);
    return ideas[id];
};

export const getIdeaShares = (state, id) => {
    const { ideaShares } = getStateBranch(state);
    return ideaShares[id];
};

export const getIdeaRange = (state, id) => {
    const { ideasRange } = getStateBranch(state);
    return ideasRange[id];
};

export const fetchIdeaLikes = id => ({ type: t.fetchIdeaLikes, id });
export const fetchIdeaRange = id => ({ type: t.fetchIdeaRange, id });
export const likeIndex = () => ({ type: t.likeIndex });
export const likeIdea = id => ({ type: t.likeIdea, id });
export const userSetIdeaRange = (id, value) => ({ type: t.userSetIdeaRange, id, value });
export const updateIndexLikes = ({ data }) => ({ type: t.updateIndexLikes, data });
export const updateIdeaLikes = (id, { data }) => ({ type: t.updateIdeaLikes, data, id });
export const updateIdeaRange = (id, { data }) => ({ type: t.updateIdeaRange, data, id });
export const incIndexLikes = () => ({ type: t.incIndexLikes });
export const incIdeaLikes = id => ({ type: t.incIdeaLikes, id });
export const setIndexShareFb = count => ({ type: t.setIndexShareFb, count });
export const setShareVk = (i, count) => ({ type: t.setShareVk, count, i });
export const fetchIdeaShares = id => ({ type: t.fetchIdeaShares, id });
export const setIdeaShareFb = (id, count) => ({ type: t.setIdeaShareFb, count, id });

if (isClient) {
    window.VK = {
        Share: {
            count: (i, count) => {
                window.store.dispatch(setShareVk(i, count));
            }
        }
    };
}

function* handleIndexLikes({ data }) {
    yield put(updateIndexLikes(data));
}

function* onClickLikeIndex() {
    try {
        // const { use } = yield select(getIndexLikes);
        if (localStorage.getItem("like-index")) return;
        localStorage.setItem("like-index", "like");
        yield put(incIndexLikes());
        const result = yield superagent.post(api.setIndexLike);
        yield handleIndexLikes(result);
    } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
    }
}


function* fetchIndexLikes() {
    try {
        const result = yield superagent.get(api.getIndexLike);
        yield handleIndexLikes(result);
    } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
    }
}

function* handleIndexFbShares({ data }) {
    yield put(setIndexShareFb(data.Facebook.share_count));
}

function* handleIdeaFbShares(id, { data }) {
    yield put(setIdeaShareFb(id, data.Facebook.share_count));
}

function* onFetchIdeaShares({ id }) {
    try {
        const result = yield superagent.get(api.getFbShareCount(makeIdeaShareLink(id)));
        yield handleIdeaFbShares(id, result);
    } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
    }

    const script = document.createElement("script");
    script.setAttribute("type", "text/javascript");
    script.setAttribute("src", api.getVkShareCount(makeIdeaShareLink(id), id));
    document.body.appendChild(script);
}

function* fetchIndexShares() {
    try {
        const result = yield superagent.get(api.getFbShareCount(locale.og.index.url));
        yield handleIndexFbShares(result);
    } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
    }

    const script = document.createElement("script");
    script.setAttribute("type", "text/javascript");
    script.setAttribute("src", api.getVkShareCount(locale.og.index.url, "-1"));
    document.body.appendChild(script);
}

function* handleIdeaLikes(id, { data }) {
    yield put(updateIdeaLikes(id, data));
}

function* handleIdeaRange(id, { data }) {
    yield put(updateIdeaRange(id, data));
}

function* onClickLikeIdea({ id }) {
    try {
        // const { use } = yield select(getIdeaLikes, id);
        // if (use) return;
        if (localStorage.getItem("like-idea-" + id)) return;
        localStorage.setItem("like-idea-" + id, "like");
        yield put(incIdeaLikes(id));
        const result = yield superagent.post(api.setIdeaLike(id));
        yield handleIdeaLikes(id, result);
    } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
    }
}

const fetchedLikes = {};
const fetchedRanges = {};

function* onFetchIdeaLikes({ id }) {
    try {
        if (fetchedLikes[id] === true) return;
        fetchedLikes[id] = true;
        const result = yield superagent.get(api.getIdeaLike(id));
        yield handleIdeaLikes(id, result);
    } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
    }
}

function* onFetchIdeaRange({ id }) {
    try {
        if (fetchedRanges[id] === true) return;
        fetchedRanges[id] = true;
        const result = yield superagent.get(api.getIdeaRange(id));
        yield handleIdeaRange(id, result);
    } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
    }
}

function* onUserSetIdeaRange({ id, value }) {
    try {
        const result = yield superagent.post(api.setIdeaRange(id, value));
        yield handleIdeaRange(id, result);
    } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
    }
}

export function* likesRootSaga() {
    yield takeEvery(t.likeIndex, onClickLikeIndex);
    yield takeEvery(t.likeIdea, onClickLikeIdea);
    yield takeEvery(t.fetchIdeaLikes, onFetchIdeaLikes);
    yield takeEvery(t.fetchIdeaRange, onFetchIdeaRange);
    yield takeEvery(t.userSetIdeaRange, onUserSetIdeaRange);
    yield takeEvery(t.fetchIdeaShares, onFetchIdeaShares);
    yield fetchIndexLikes();
    yield fetchIndexShares();
}

const initialState = {
    index: {
        likes: 2,
        use: false,
        loaded: false
    },
    ideas: {},
    ideasRange: {},
    indexShares: {
        fb: 0,
        vk: 0
    },
    ideaShares: {}
};

const ideasReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case t.setIdeaShareFb:
            return {
                ...state,
                ideaShares: {
                    ...state.ideaShares,
                    [action.id]: {
                        ...(state.ideaShares[action.id] || {}),
                        fb: action.count
                    }
                }
            };

        case t.setShareVk: {
            if (action.i === -1) {
                return {
                    ...state,
                    indexShares: {
                        ...state.indexShares,
                        vk: action.count
                    }
                };
            }

            return {
                ...state,
                ideaShares: {
                    ...state.ideaShares,
                    [action.i]: {
                        ...(state.ideaShares[action.i] || {}),
                        vk: action.count
                    }
                }
            };
        }

        case t.setIndexShareFb:
            return {
                ...state,
                indexShares: {
                    ...state.indexShares,
                    fb: action.count
                }
            };

        case t.updateIdeaRange:
            return {
                ...state,
                ideasRange: {
                    ...state.ideasRange,
                    [action.id]: {
                        ...action.data,
                        loaded: true
                    }
                }
            };

        case t.updateIdeaLikes:
            return {
                ...state,
                ideas: {
                    ...state.ideas,
                    [action.id]: {
                        ...action.data,
                        loaded: true
                    }
                }
            };

        case t.updateIndexLikes:
            return {
                ...state,
                index: {
                    ...action.data,
                    loaded: true
                }
            };

        case t.incIndexLikes:
            return {
                ...state,
                index: {
                    ...state.index,
                    likes: state.index.likes + 1,
                    use: true
                }
            };

        case t.incIdeaLikes: {
            const idea = state.ideas[action.id] || { likes: 0, use: true };
            return {
                ...state,
                ideas: {
                    ...state.ideas,
                    [action.id]: {
                        ...idea,
                        likes: idea.likes + 1,
                        loaded: true
                    }
                }
            };
        }

        default:
            return state;
    }
};

export default ideasReducer;
