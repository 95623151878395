import { connect } from 'react-redux';
import {
  getIdeaLikes,
  getIdeaShares,
  likeIdea,
} from './likesReducer';

const stateToProps = (_, props) => state => ({
  ideaLikes: getIdeaLikes(state, props.idea.id),
  ideaShares: getIdeaShares(state, props.idea.id),
});

const dispatchToProps = {
  likeIdea,
};

export default connect(stateToProps, dispatchToProps)(
  ({ children, ...props }) => children({
    ...props,
    likeIdea: () => props.likeIdea(props.idea.id),
  }),
);
