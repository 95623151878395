import React from 'react';
import PropTypes from 'prop-types';
import { Ui, locale } from '..';
import { RepostProvider } from './Repost';

const BlackBox = ({
  isBlackBoxOpened,
  isRepostVisible,
  storeRef,
  onClickSubmit,
}) => (
  <Ui.Touchable>
    <Ui.Background
      theme="black"
      isBottomBlackBox
      isBlackBoxOpened={isBlackBoxOpened}
      isRepostVisible={isRepostVisible}
    />
    <Ui.Background
      theme="black"
      isBottomBlackBox
      isAnimatedBottomBlackBox
      isBlackBoxOpened={isBlackBoxOpened}
      isRepostVisible={isRepostVisible}
      storeRef={storeRef}
    >
      <Ui.ColumnsWrapper>
        <Ui.Column>
          <Ui.Icon type="logo-large" inlineBlock />
          <Ui.Padding height={6.9} />
          <Ui.BlackBoxTitle />
          <Ui.Padding height={3} flexGrow />
          <RepostProvider>
            {({ reposted, repostVk, repostFb }) => (
              <Ui.BlackBoxDownload
                reposted={reposted}
                repostVk={repostVk}
                repostFb={repostFb}
              />
            )}
          </RepostProvider>
        </Ui.Column>
        <Ui.Column>
          <Ui.BlackBoxPoster />
        </Ui.Column>
        <Ui.Column>
          <Ui.BlackBoxMenu />
          <Ui.Padding height={3} flexGrow />
          <Ui.Text
            white
            navigo
            fontSize={1.8}
            lineHeight={2.5}
            html={locale.blackBox.orderText}
          />
          <Ui.Padding height={3} />
          <Ui.Button to={locale.orderHref + '?from=giveaway_page_poster'}>
            <Ui.Text navigo ls bold fontSize={1}>
              {locale.submit}
            </Ui.Text>
          </Ui.Button>
        </Ui.Column>
      </Ui.ColumnsWrapper>
    </Ui.Background>
  </Ui.Touchable>
);

BlackBox.propTypes = {
  isBlackBoxOpened: PropTypes.bool.isRequired,
  isRepostVisible: PropTypes.bool.isRequired,
  storeRef: PropTypes.func.isRequired,
  onClickSubmit: PropTypes.func.isRequired,
};

export default BlackBox;
