import React from 'react';
import locale from '../../locale';
import Text from '../Text';
import './blackBoxMenu.css';

const BlackBoxMenu = () => (
  <ul className="blackBoxMenu">
    {locale.blackBox.menu.map(({ title, href }) => (
      <li key={title}>
        <a href={href}>
          <Text fontSize={2} lineHeight={3.5} colored>
            {title}
          </Text>
        </a>
      </li>
    ))}
  </ul>
);

export default BlackBoxMenu;
