import { connect } from 'react-redux';
import {
  getReposted,
  repostVk,
  repostFb,
} from './repostReducer';

const stateToProps = state => ({
  reposted: getReposted(state),
});

const dispatchToProps = {
  repostVk,
  repostFb,
};

export default connect(stateToProps, dispatchToProps)(
  ({ children, ...props }) => children({ ...props }),
);
