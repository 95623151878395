import React from 'react';
import { Provider } from 'react-redux';

import createStore from './src/createStore';

const getInitialStateDefault = () => ({});

// eslint-disable-next-line react/display-name,react/prop-types
export default (getInitialState = getInitialStateDefault) => ({ element }) => {
  const store = createStore(getInitialState(element));
  return (
    <Provider store={store}>
      {element}
    </Provider>
  );
};
