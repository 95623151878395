import React from 'react';
import PropTypes from 'prop-types';
import Text from '../Text';
import locale from '../../locale';

import Icon from '../Icon/Icon';
import Touchable from '../Touchable';
import './logo.css';

const Logo = ({ href }) => (
  <Touchable>
    <a
      href={href}
      className="logo"
    >
      <div className="logo-icon">
        <Icon type="logo" />
      </div>
      <div className="logo-text-wrapper">
        <div className="logo-title">
          <Text heavy white>
            {locale.brandName}
          </Text>
        </div>
        <div className="logo-instagram">
          <Text white>
            {locale.instagram}
          </Text>
        </div>
      </div>
    </a>
  </Touchable>
);

Logo.propTypes = {
  href: PropTypes.string,
};

Logo.defaultProps = {
  href: locale.instagramHref,
};

export default Logo;
