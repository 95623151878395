import flow from 'lodash/flow';
import {
  put,
  call,
  takeEvery,
  select,
} from 'redux-saga/effects';
import shuffle from 'lodash/shuffle';
import last from 'lodash/last';
import findIndex from 'lodash/findIndex';
import random from 'lodash/random';
import { delay } from 'redux-saga';
import { navigate } from 'gatsby';
import { withPrefix } from 'gatsby-link';

import { iconTypes } from '../../ui/Icon/Icon';
import isClient from '../../isClient';
import locale, { url } from '../../locale';
import { vkShare, fbShare } from '../../share';

const t = {
  showNextIdea: 'showNextIdea',
  setNextIdeaIndex: 'setNextIdeaIndex',
  runAnimation: 'runAnimation',
  runIndexAnimation: 'runIndexAnimation',
  openIdeas: 'openIdeas',
  ideaRepostVk: 'ideaRepostVk',
  ideaRepostFb: 'ideaRepostFb',
};

export const isIdeaPagePath = path => /idea/.test(path);
export const getIdeaIdByPath = path => last(path.split('/').filter(e => e));
export const getCurrentIdeaIndexByPath = (path, state) => {
  const ideaId = getIdeaIdByPath(path);
  const ideaIndex = findIndex(state.ideas, { id: ideaId });
  return ideaIndex;
};

const getNextIdeaIndex = ({ currentIdeaIndex, ideas }) => {
  const nextIndex = currentIdeaIndex + 1;
  return nextIndex > ideas.length - 1
    ? 0
    : nextIndex;
};

const getStateBranch = state => state.ideasReducer;

export const getCurrentIdea = flow([
  getStateBranch,
  ({ currentIdeaIndex, ideas }) => ideas[currentIdeaIndex],
]);

export const getAnimationStarted = flow([
  getStateBranch,
  ({ animationStarted }) => animationStarted,
]);

export const getIndexAnimationStarted = flow([
  getStateBranch,
  ({ indexAnimationStarted }) => indexAnimationStarted,
]);

export const getNextIdea = flow([
  getStateBranch,
  state => state.ideas[getNextIdeaIndex(state)],
]);

export const openIdeas = () => ({ type: t.openIdeas });
export const runAnimation = () => ({ type: t.runAnimation });
export const runIndexAnimation = () => ({ type: t.runIndexAnimation });
export const setNextIdeaIndex = () => ({ type: t.setNextIdeaIndex });
export const showNextIdea = () => ({ type: t.showNextIdea });
export const ideaRepostVk = () => ({ type: t.ideaRepostVk });
export const ideaRepostFb = () => ({ type: t.ideaRepostFb });

export const makeIdeaLink = id => `/idea/${id}`;
const addAnimationHash = ideaUrl => `${ideaUrl}#animation`;

function* redirectToCurrentIdea({ animation } = { animation: false }) {
  const { id } = yield select(getCurrentIdea);
  const ideaUrl = makeIdeaLink(id);
  navigate(
    animation
      ? addAnimationHash(ideaUrl)
      : ideaUrl,
  );
}

function* onShowNextIdea() {
  const animationStarted = yield select(getAnimationStarted);
  if (animationStarted) return;
  yield put(runAnimation());
  yield call(delay, 500);
  yield put(setNextIdeaIndex());
  yield redirectToCurrentIdea();
}

function* onOpenIdeas() {
  yield put(runIndexAnimation());
  yield call(delay, 500);
  yield redirectToCurrentIdea({ animation: true });
}

function* onClickRepostVk() {
  const idea = yield select(getCurrentIdea);
  const ideaUrl = `${url}${makeIdeaLink(idea.id)}`;
  const { image, text } = idea;

  vkShare(ideaUrl);
}

function* onClickRepostFb() {
  const idea = yield select(getCurrentIdea);

  fbShare(`${url}${makeIdeaLink(idea.id)}`);
}

export function* ideasRootSaga() {
  yield takeEvery(t.showNextIdea, onShowNextIdea);
  yield takeEvery(t.openIdeas, onOpenIdeas);
  yield takeEvery(t.ideaRepostVk, onClickRepostVk);
  yield takeEvery(t.ideaRepostFb, onClickRepostFb);
}

const getRandomEmojiIcon = () => {
  const emojiIcons = iconTypes
    .filter(iconType => iconType.length === 2);
  const randomIcon = emojiIcons[
    random(0, emojiIcons.length - 1)
  ];
  return randomIcon;
};

const ideas = shuffle(locale.ideas).map(idea => ({
  ...idea,
  image: withPrefix(`/${idea.image}`),
  emoji: getRandomEmojiIcon(),
}));

export const initialState = {
  ideas,
  currentIdeaIndex: (() => {
    if (isClient) {
      const { pathname: path } = window.location;
      if (isIdeaPagePath(path)) return getCurrentIdeaIndexByPath(path, { ideas });
      return 0;
    }

    return 0;
  })(),
  animationStarted: false,
  indexAnimationStarted: false,
};

const ideasReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case t.setNextIdeaIndex:
      return {
        ...state,
        currentIdeaIndex: getNextIdeaIndex(state),
        animationStarted: false,
      };

    case t.runAnimation:
      return {
        ...state,
        animationStarted: true,
      };

    case t.runIndexAnimation:
      return {
        ...state,
        indexAnimationStarted: true,
      };

    default:
      return state;
  }
};

export default ideasReducer;
