import React from 'react';
import PropTypes from 'prop-types';
import './columns.css';

const ColumnsWrapper = ({ children }) => (
  <div className="columnsWrapper">
    {children}
  </div>
);

const Column = ({ children }) => (
  <div className="column">
    {children}
  </div>
);

ColumnsWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element.isRequired,
    PropTypes.arrayOf(
      PropTypes.element.isRequired,
    ).isRequired,
  ]).isRequired,
};
Column.propTypes = ColumnsWrapper.propTypes;

export { ColumnsWrapper, Column };
