import MD from 'mobile-detect';
import isClient from './isClient';

const device = (() => {
  let isDesktop = false;
  let isTablet = false;
  let isPhone = false;

  if (isClient) {
    const dev = new MD(window.navigator.userAgent);

    if (dev.phone()) {
      isPhone = true;
    } else if (dev.tablet()) {
      isTablet = true;
    } else {
      isDesktop = true;
    }
  }

  return {
    isPhone,
    isTablet,
    isDesktop,
  };
})();

if (isClient) {
  const getOrientationClassName = () => {
    if (window.innerHeight > window.innerWidth) {
      return ' portrait';
    }

    return ' landscape';
  };

  const setBodyClassNames = () => {
    let className = '';

    if (device.isPhone) {
      className += ' phone';
    } else if (device.isTablet) {
      className += ' tablet';
    } else {
      className += ' desktop';
    }
    className += getOrientationClassName();

    if (document.body.className !== className) {
      document.body.className = className;
    }
  };

  setBodyClassNames();

  window.addEventListener('resize', setBodyClassNames);
}

export default device;
