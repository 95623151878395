import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import './background.css';

const Background = ({
  children,
  theme,
  fullScreen,
  isBlackBoxOpened,
  isRepostVisible,
  isBottomBlackBox,
  isMain,
  isAnimatedBottomBlackBox,
  storeRef,
  zIndex,
}) => {
  const style = {};
  if (zIndex) style.zIndex = zIndex;
  return (
    <div
      className={cx(
        'background',
        `background--${theme}`, {
          'background--fullScreen': fullScreen,
          'background--is-bottom-black-box': isBottomBlackBox,
          'background--is-repost-visible': isRepostVisible,
          'background--is-black-box-opened': isBlackBoxOpened,
          'background--main': isMain,
          'background--is-animated-black-box': isAnimatedBottomBlackBox,
        },
      )}
      ref={storeRef}
      style={style}
    >
      {children}
    </div>
  );
};

Background.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element.isRequired,
    PropTypes.arrayOf(
      PropTypes.element.isRequired,
    ).isRequired,
  ]),
  theme: PropTypes.oneOf([
    'none',
    'default',
    'black',
  ]),
  fullScreen: PropTypes.bool,
  isBottomBlackBox: PropTypes.bool,
  isBlackBoxOpened: PropTypes.bool,
  isRepostVisible: PropTypes.bool,
  isMain: PropTypes.bool,
  isAnimatedBottomBlackBox: PropTypes.bool,
  storeRef: PropTypes.func,
  zIndex: PropTypes.number,
};

Background.defaultProps = {
  children: null,
  theme: 'default',
  fullScreen: false,
  isBottomBlackBox: false,
  isBlackBoxOpened: false,
  isRepostVisible: false,
  isMain: false,
  isAnimatedBottomBlackBox: false,
  storeRef: null,
  zIndex: null,
};

export default Background;
