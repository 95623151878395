import locale, { url as urlOrigin } from './locale';

const vkShare = (url, title, image) => {
  const path = `http://vk.com/share.php?url=${url}${image ? `&image=${urlOrigin + image}` : ''}${title ? `&title=${title}` : ''}`;
  console.log(path)
  window.open(path, '_blank');
};

const fbShare = (url, title, image) => {
  window.open(`https://www.facebook.com/sharer/sharer.php?u=${url}`, '_blank');
};

export {
  vkShare,
  fbShare,
};
