import { take, put } from "redux-saga/effects";
import { eventChannel, delay } from "redux-saga";

import { markAppLoaded } from "./loaderReducer";

function getOnAppLoadedEventChannel() {
    return eventChannel(emitter => {
        if (document.readyState === "complete") {
            setTimeout(() => {
                emitter("already loaded");
            }, 0);
        }
        window.addEventListener("load", emitter);

        return () => {
            window.removeEventListener("load", emitter);
        };
    });
}

export default function* watchOnAppLoaded() {
    yield delay(300);
    yield put(markAppLoaded());
}
