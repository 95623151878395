import RangeSliderDataProvider from './RandeSliderDataProvider';
import {
  loaderReducer,
  loaderSaga,
  LoaderProvider,
} from './Loader';
import SizeContoller from './SizeContoller';
import BottomBoxController from './BottomBoxController';
import {
  IdeaProvider,
  ideasReducer,
  ideasRootSaga,
  OpenIdeasProvider,
  IdeaRepostProvider,
  Ideas,
} from './Ideas';
import {
  RepostProvider,
  repostReducer,
  repostRootSaga,
} from './Repost';
import ToggleBlackBoxOnScroll from './ToggleBlackBoxOnScroll';
import BlackBox from './BlackBox';
import {
  likesReducer,
  likesRootSaga,
  IndexLikesProvider,
  IdeaLikesProvider,
} from './Likes';

export {
  RangeSliderDataProvider,

  loaderReducer,
  loaderSaga,
  LoaderProvider,

  SizeContoller,
  BottomBoxController,

  IdeaProvider,
  ideasReducer,
  ideasRootSaga,
  OpenIdeasProvider,
  IdeaRepostProvider,
  Ideas,

  RepostProvider,
  repostReducer,
  repostRootSaga,

  ToggleBlackBoxOnScroll,
  BlackBox,

  likesReducer,
  likesRootSaga,
  IndexLikesProvider,
  IdeaLikesProvider,
};
