import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Touchable from '../Touchable';
import './moreIdeas.css';
import './moreIdeasPhone.css';

const MoreIdeas = ({
  children,
  large,
  small,
  smallWhite,
  onClick,
  touchable,
}) => {
  const TouchableWrapper = touchable
    ? Touchable
    : React.Fragment;
  return (
    <TouchableWrapper>
      <button
        type="button"
        onClick={onClick}
        className={cx('more-ideas', {
          'more-ideas--large': large,
          'more-ideas--small': small,
          'more-ideas--small-white': smallWhite,
        })}
      >
        <span className="more-ideas-wrapper1">
          <span className="more-ideas-wrapper2">
            {children}
          </span>
        </span>
      </button>
    </TouchableWrapper>
  );
};

MoreIdeas.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element.isRequired,
    PropTypes.arrayOf(
      PropTypes.element.isRequired,
    ).isRequired,
  ]).isRequired,
  large: PropTypes.bool,
  small: PropTypes.bool,
  smallWhite: PropTypes.bool,
  touchable: PropTypes.bool,
  onClick: PropTypes.func,
};

MoreIdeas.defaultProps = {
  large: false,
  small: false,
  smallWhite: false,
  onClick: null,
  touchable: false,
};

export default MoreIdeas;
