import React from 'react';
import PropTypes from 'prop-types';

const Touchable = ({ children }) => (
  <div className="touchable">
    {children}
  </div>
);

Touchable.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.element.isRequired,
    ).isRequired,
    PropTypes.element.isRequired,
    PropTypes.string.isRequired,
  ]).isRequired,
};

export default Touchable;
