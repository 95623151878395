import { connect } from 'react-redux';
import {
  openIdeas,
  getIndexAnimationStarted,
} from './ideasReducer';

const stateToProps = state => ({
  isIndexAnimationStarted: getIndexAnimationStarted(state),
});

const dispatchToProps = {
  openIdeas,
};

export default connect(stateToProps, dispatchToProps)(
  ({ children, ...props }) => children({ ...props }),
);
