import { Component } from 'react';
import PropTypes from 'prop-types';

class BottomBoxController extends Component {
  static propTypes = {
    children: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      isBlackBoxOpened: false,
      isRepostVisible: false,
    };
  }

  onClickToggle = () => {
    const { isBlackBoxOpened } = this.state;
    this.setState({
      isBlackBoxOpened: !isBlackBoxOpened,
    });
  }

  onShowRepost = () => {
    this.setState({
      isRepostVisible: true,
    });
  }

  onHideRepost = () => {
    this.setState({
      isRepostVisible: false,
    });
  }

  render() {
    const { isBlackBoxOpened, isRepostVisible } = this.state;
    const { children } = this.props;

    return children({
      isBlackBoxOpened,
      isRepostVisible,
      onClickToggle: this.onClickToggle,
      onShowRepost: this.onShowRepost,
      onHideRepost: this.onHideRepost,
    });
  }
}

export default BottomBoxController;
