import flow from 'lodash/flow';
import { takeEvery } from 'redux-saga/effects';
import { vkShare, fbShare } from '../../share';
import locale from '../../locale';

const t = {
  repostVk: 'repostVk',
  repostFb: 'repostFb',
};

export const repostVk = () => ({ type: t.repostVk });
export const repostFb = () => ({ type: t.repostFb });

const getStateBranch = state => state.repostReducer;

function* onClickRepostBlackBoxVk() {
  vkShare(locale.og.blackBox.url);
  yield null;
}

function* onClickRepostBlackBoxFb() {
  fbShare(locale.og.blackBox.url);
  yield null;
}

export function* repostRootSaga() {
  yield takeEvery(t.repostVk, onClickRepostBlackBoxVk);
  yield takeEvery(t.repostFb, onClickRepostBlackBoxFb);
}

export const getReposted = flow([
  getStateBranch,
  ({ reposted }) => reposted,
]);

const initialState = {
  reposted: false,
};

const repostReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case t.repostFb:
    case t.repostVk:
      return {
        ...state,
        reposted: true,
      };

    default:
      return state;
  }
};

export default repostReducer;
