import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import Svg from './title_outlines.svg';
import Touchable from '../Touchable';
import locale from '../../locale';
import './title1.css';

const Title = ({ hidden }) => (
  <div
    className={cx('title', {
      'title--hidden': hidden,
    })}
  >
    <Touchable>
      <img src={Svg} alt={`${locale.h1FirstLine} ${locale.h1SecondLine}`} />
      <h1>{`${locale.h1FirstLine} ${locale.h1SecondLine}`}</h1>
    </Touchable>
  </div>
);

Title.propTypes = {
  hidden: PropTypes.bool,
};

Title.defaultProps = {
  hidden: false,
};

export default Title;
