import React from 'react';
import PropTypes from 'prop-types';

const Padding = ({ height, flexGrow, width }) => {
  const style = height
    ? { minHeight: `${height}rem` }
    : { minWidth: `${width}rem` };
  if (flexGrow) style.flexGrow = 2;

  return (
    <div className="padding" style={style} />
  );
};

Padding.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  flexGrow: PropTypes.bool,
};

Padding.defaultProps = {
  flexGrow: false,
  height: null,
  width: null,
};

export default Padding;
