import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import animations from "./animations";
import "./animation.css";

const animationTypes = Object.keys(animations);

const typeToSize = {
    1: {
        width: "20rem"
    },
    2: {
        width: "20rem"
    },
    3: {
        width: "20rem"
    },
    4: {
        width: "19.7rem"
    },
    5: {
        width: "20rem"
    },
    6: {
        width: "20rem"
    },
    7: {
        width: "20rem"
    },
    9: {
        width: "20rem"
    },
    17: {
        width: "20rem"
    }
};

const Animation = ({ type, margin, scale, hiddenOnPhone, hiddenOnTablet, hidden, rotate, flipHorizontal }) => {
    const { gif, webm, mp4, png, width, height } = animations[type];
    const overridenSize = typeToSize[type];
    const style = {
        ...(overridenSize || { width, height })
    };

    if (margin) style.margin = margin;
    style.transform = "";
    if (scale) style.transform = `scale(${scale})`;
    if (rotate) style.transform = `${style.transform} rotate(${rotate})`;
    if (flipHorizontal) style.transform = `${style.transform} scaleX(-1)`;

    if (webm) {
        return (
            /* eslint-disable jsx-a11y/media-has-caption */
            <video
                playsInline
                muted
                autoPlay
                loop
                poster={png}
                style={style}
                className={cx("animation", {
                    hiddenOnPhone,
                    hiddenOnTablet,
                    "animation--hidden": hidden
                })}
            >
                <source src={webm} type="video/webm;" />
            </video>
        );
    }

    return (
        <img
            alt="animation"
            src={gif}
            style={style}
            className={cx("animation", {
                hiddenOnPhone,
                hiddenOnTablet
            })}
        />
    );
};

Animation.propTypes = {
    type: PropTypes.oneOf(animationTypes).isRequired,
    margin: PropTypes.string,
    scale: PropTypes.number,
    hiddenOnPhone: PropTypes.bool,
    hiddenOnTablet: PropTypes.bool,
    hidden: PropTypes.bool,
    flipHorizontal: PropTypes.bool,
    rotate: PropTypes.string
};

Animation.defaultProps = {
    margin: "",
    scale: null,
    hiddenOnPhone: false,
    hiddenOnTablet: false,
    hidden: false,
    flipHorizontal: false,
    rotate: ""
};

export { animationTypes };
export default Animation;
