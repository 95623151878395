// it's generated file, don't touch, just run
// $ npm run icons

const types = [
  'arrow',
  'giveaway',
  'heart',
  'logo-large',
  'logo',
  'poster',
  'stars',
  '☺️',
  '⭐️',
  '🐯',
  '🐱',
  '👍',
  '💎',
  '💩',
  '🔥',
  '🔮',
  '😇',
  '😈',
  '😊',
  '😋',
  '😌',
  '😍',
  '😎',
  '😘',
  '😸',
  '😺',
  '😻',
  '😽',
  '🙆',
  '🤗',
];

export default types;
