import React from 'react';
import PropTypes from 'prop-types';
// import Link from 'gatsby-link';
import Text from '../Text';
import Icon from '../Icon/Icon';
import Touchable from '../Touchable';
import './more.css';
import locale from '../../locale';

const More = ({ onClick }) => (
  <Touchable>
    <button onClick={onClick} type="button" className="see-more">
      <span className="see-more-wrapper">
        <span className="see-more-icon-wrapper">
          <span className="see-more-icon">
            <Icon type="arrow" />
          </span>
        </span>
        <span className="see-more-text">
          <Text bold>
            {locale.seeMore}
          </Text>
        </span>
      </span>
    </button>
  </Touchable>
);

More.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default More;
